import { motion } from 'framer-motion';
import * as Motioner from '../utils/MotionerUtil';
import { twMerge } from 'tailwind-merge';

const Button = ({ children, variant, size, disabled, startAdornment, endAdornment, className, ...properties }) => (
    <motion.button className={twMerge(`flex items-center justify-center gap-3 px-8 h-14 cursor-pointer border-none font-bold rounded-lg brightness-[100%] hover:brightness-[80%] whitespace-nowrap bg-blue-900 text-white`, className)} {...Motioner.click} {...properties}>
        {children}
    </motion.button>
);

export default Button;
